export const Footerstyles = {
  footer: {
    backgroundColor: '#1a1a1a',
    color: '#fff',
    padding: '30px 20px 0px 20px',
    width: '100%',
    backgroundColor: '#171B2A',
  },
  footerSection: {
    marginBottom: '20px',
  },
  footerTitle: {
    fontSize: '1.5rem',
    marginBottom: '10px',
  },
  footerLink: {
    color: '#fff',
    textDecoration: 'none',
    display: 'block',
    marginBottom: '5px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  socialIcons: {
    display: 'flex',
    marginTop: '10px',
    '& a': {
      color: '#fff',
      marginRight: '10px',
      '&:hover': {
        color: '#ddd',
      },
    },
  },
  map: {
    width: '100%',
    height: '150px',
    border: '0',
  },
  logo: {
    width: '90%',
  },
  description: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  copyright: {
    textAlign: 'center',
    padding: '10px',
    borderTop: '1px solid #333',
  },
};
