import { BrowserRouter as Router, Route, Routes,  } from "react-router-dom";
import "./App.css";
import Homepage from "./Pages/Home/Home";
import BookScanning from "./Pages/BookingScanning/BookScanning";
import Booklabtest from "./Pages/BookingLabTest/Booklabtest";
import HealthPackage from "./Pages/HealthPackage/HealthPackage";
import AppointmentBooking from "./Pages/AppointmentBooking/Appointmentbooking"
import React from 'react';
import Footer from "./Common/Components/footer/Footer";
import NavbarComponent from "./Common/Components/navigationbar/navbar";
import TermsAndConditions from "./Pages/TermsAndConditons/TermsAndConditions";


function App() {
  return (
    <Router>
      <div className="App">
        <NavbarComponent/>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/book-test" element={<Booklabtest />} />
          <Route path="/book-scanning" element={<BookScanning />} />
          <Route path="/health-packages" element={<HealthPackage />} />
          <Route path="/Appointment-Booking/:id/:type" element={<AppointmentBooking />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;












