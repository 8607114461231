export const Theme = {
  color: {
    primary: "#1131A2",
    secondary: "#6F8CF4",
    optional: "#046B4B",
    heading: "#00281B",
    body: "#7B7B7B",
    icon: "#617A9E",
    border: "#E1E1E1",
    white: "#FFFFFF",
    transparent: "transparent",
    Headingcolor: "#002C58",
    linearGradient: ("90deg, #1976D3 0%, #0D3D6D 100%"),

  },
  borderWhite: {
    border: "2px solid",
    borderColor: "#FFFFFF",
  },
  borderSecondary: {
    border: "2px solid",
    borderColor: "#FAC02E",
  },
  textFK2DW800S60: {
    fontFamily: "K2D",
    fontSize: "60px",
    fontWeight: "800",
    lineHeight: "78px",
   
  },
  textFK2DW800S20: {
    fontFamily: "K2D",
    fontSize: "20px",
    fontWeight: "800",
    lineHeight: "39px",
    textAlign: "center",
  },
  textFK2DW800S45: {
    fontFamily: "K2D",
    fontSize: "45px",
    fontWeight: "800",
    lineHeight: "48.5px",
    textAlign: "center",
  },
  textFK2DW800S50: {
    fontFamily: "K2D",
    fontSize: "50px",
    fontWeight: "800",

  },

  textFHeeboW400S18: {
    fontFamily: "Heebo",
    fontSize: "18px",
    fontWeight: "400",
    LineHeight: "26.44px",



  },
  textFHeeboW500S18: {
    fontFamily: "Heebo",
    fontSize: "18px",
    fontWeight: "500",
    LineHeight: "26.44px",

  },
  textFHeeboW500S22: {
    fontfamily: "Heebo",
    fontsize: "22px",
    fontweight: "500",
    lineHeight: "32.31px",
  },
  textFHeeboW500S25: {
    fontFamily: "Heebo",
    fontSize: "25px",
    fontWeight: "500",
    lineHeight: "36.72px",
  },
  textFHeeboW500S14: {
    fontFamily: "Heebo",
    fontSize: "14px",
    fontWeight: "500",
  },

  textFPoppinsW400S20: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "25.42px",
  },
  textFPoppinsW400S18: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "25.42px",
  },
  textFHeeboWS18: {
    fontFamily: "Heebo",
    fontSize: "18px",
    
    LineHeight: "26.44px",
  }
};