import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Button, Container, Image, Stack } from "react-bootstrap";
import { BookingLabTeststyles } from "./Booklabtest.styles.js";
import banner from "../../Resources/Assets/tests.png";
import doctor from "../../Resources/Assets/Vector.png";
import checkup from "../../Resources/Assets/image 8.png";
import collection from "../../Resources/Assets/image 9.png";
import report from "../../Resources/Assets/image 10.png";
import { useInit } from "./Bookinglabtest.hook.js";
import { useNavigate } from "react-router-dom";

const useStyles = createUseStyles(BookingLabTeststyles);

const Booklabtest = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClick = (id) => {
    const type = "Test";  
    navigate(`/Appointment-Booking/${id}/${type}`);
  };
  const { state, actions } = useInit();
  React.useEffect(() => {
    actions.getBookinglabtestAction();
  }, []);
  console.log("STATE::", state);
  const labTests =
    state.Bookinglabtest?.tests?.length > 0 ? state.Bookinglabtest?.tests : [];

  return (
    <Container fluid className={classes.Container}>
      <Stack direction="horizontal" className={classes.HompageHeadercontainer}>
        <Stack className={classes.Headerheading}>
          <h1 className={classes.Headerh1}>
            Book a test now and <br /> take a proactive step <br />
            towards better health!
          </h1>
          <div className={classes.Headerp}>
            <p>
              Ensure your health with our reliable and efficient <br /> lab
              testing services. Schedule your appointment <br /> today and
              experience the convenience and <br /> accuracy you can trust.
            </p>
          </div>
        </Stack>
        <Stack className={classes.HompageHeaderimage}>
          <Image src={banner} alt="Banner" className={classes.ImageStyle} />
        </Stack>
      </Stack>

      <Stack className={classes.bookLabTestContainer}>
        <h className={classes.bookLabTestTitle}>Book Lab Tests</h>
        <p className={classes.bookLabTestDescription}>
          Ensure your health with our accurate and reliable lab testing
          services. Schedule your lab test today for prompt and <br/> professional
          results. Book a lab test now and take charge of your health!
        </p>
        <div className={classes.labtestontainer}>
          {labTests.map((test, index) => (
            <div key={index} className={classes.Labtestitem}>
              <h3 className={classes.itemTitle}>{test.name}</h3>
              <p className={classes.itemPrice}>{test.price}</p>
              <button
                className={classes.bookNowButton}
                onClick={() => handleButtonClick(test.id)}
              >
                Book Now
              </button>
            </div>
          ))}
        </div>
      </Stack>

      <Stack>
        <h className={classes.HomeSampleh}>Home Sample Available</h>
        <div className={classes.HomeSamplecontainer}>
          <div className={classes.item}>
            <img className="Samplelimg" src={doctor} alt="First slide" />
            <span className={classes.text}>Find a Test</span>
          </div>
          <div className={classes.item}>
            <img className="Sampleimg" src={checkup} alt="First slide" />
            <span className={classes.text}>Buy Health Checkup</span>
          </div>
          <div className={classes.item}>
            <img className="Sampleimg" src={collection} alt="First slide" />
            <span className={classes.text}>Home Sample Collection</span>
          </div>
          <div className={classes.item}>
            <img
              className="SampleCollectionimg"
              src={report}
              alt="First slide"
            />
            <span className={classes.text}>Download Reports</span>
          </div>
        </div>
      </Stack>

      
    </Container>
  );
};

export default Booklabtest;
