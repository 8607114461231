import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import logo from '../../../Resources/Assets/polo-logo.png';
import { Footerstyles } from './Footer.styles';

const useStyles = createUseStyles(Footerstyles);

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container>
        <Row>
          <Col xs={12} md={6} lg={3} className={classes.footerSection}>
            <img src={logo} alt="logo" className={classes.logo} />
            <p className={classes.description}>
              At Polo Scan and Diagnostic, we understand the importance of convenient access to quality healthcare services.
            </p>
            <div className={classes.socialIcons}>
              <a href="https://www.facebook.com/profile.php?id=61560186608490" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="https://www.instagram.com/?next=%2F&hl=en" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="https://x.com/PoloDiagnostics" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            </div>
          </Col>

          <Col xs={12} md={6} lg={3} className={classes.footerSection}>
            <h3 className={classes.footerTitle}>Main Info</h3>
            <a href="/" className={classes.footerLink}>Home</a>
            <a href="/book-test" className={classes.footerLink}>Book a Test</a>
            <a href="/book-scanning" className={classes.footerLink}>Book For Scanning</a>

            <a href="/health-packages" className={classes.footerLink}>Health Packages</a>
            <a href="/terms-and-conditions" className={classes.footerLink}>Terms And Conditions</a>
          </Col>

          <Col xs={12} md={6} lg={3} className={classes.footerSection}>
            <h3 className={classes.footerTitle}>Services</h3>
            <a  className={classes.footerLink}>Glycated Hemoglobine</a>
            <a  className={classes.footerLink}>Liver Function Test</a>
            <a  className={classes.footerLink}>Creatinine</a>
            <a  className={classes.footerLink}>Thyroid Profile</a>
            <a  className={classes.footerLink}>Electrolytes</a>
            <a  className={classes.footerLink}>Calcium</a>
          </Col>

          <Col xs={12} md={6} lg={3} className={classes.footerSection}>
            <h3 className={classes.footerTitle}>Address</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d951.4004367804029!2d78.623395!3d17.4787655!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9dcf6d382b71%3A0x7b4f6235e87a0d7e!2sJ%20R%20Reddy%20Lions%20hospital!5e0!3m2!1sen!2sin!4v1716300392174!5m2!1sen!2sin"
              width="100%"
              height="150"
              frameBorder="0"
              className={classes.map}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
            <p> H.No. 10-3-105 Lakshmi Nagar, Phase-2, R.L. Nagar, Keesara ,Medchal Dist Hyderabad, Telangana 500083</p>
          </Col>
        </Row>
      </Container>
      <div className={classes.copyright}>
        Copy All Rights Reserved @polo scan and diagnostics 2024
      </div>
    </footer>
  );
};

export default Footer;
