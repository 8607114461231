import { HttpClient } from '../src/Common/HttpClient/HttpClient';

export const searchTests = async (query) => {
  try {
    console.log(`Searching for: ${query}`);
    const response = await HttpClient.post('/search', { query });
    console.log('Response:', response);
    if (response.status === 200) {
      return {
        statusCode: 200,
        data: response.data,
      };
    } else {
      return {
        statusCode: response.status,
        error: 'Something went wrong, please try again',
      };
    }
  } catch (error) {
    console.error('Error:', error);
    return {
      statusCode: 404,
      error: error.message,
    };
  }
};
