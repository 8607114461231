import { HEALTH_PACKAGE_ACTIONS } from "./HealthPackage.Action";

export const HealthPackageReducer = (state, action) =>{
  switch (action.type) {
      case HEALTH_PACKAGE_ACTIONS.SET_LOADING:
          return {
              ...state,
              isLoading: action.data
          };
      case HEALTH_PACKAGE_ACTIONS.SET_ERROR:
          return {
              ...state,
              error: action.data
          };

      case HEALTH_PACKAGE_ACTIONS.SET_HEALTH_PACKAGE:
          return {
              ...state,
              healthPackage : action.data
          };
      default:
          return { ...state };
  }
}