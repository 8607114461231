// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exo-2 {
  font-family: "Exo 2", sans-serif;
}

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7vh;
    background-color: #000000;
   
  }

  .flex{
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  .contactsFont{
    color: white;
    font-size: 15px;
    font-weight: 100;
  } 

  .contacts{
    display: flex;
    align-items: center;
  
   
  }

  .socialMedia{
    display: flex;
    align-items: center;
    margin-right: 80px;
   
   
  }
  .contact_Email{
    margin-inline: 100px;
  }
  
  .contactImage{
    width: 20px;
    padding-inline: 10px;
  }

  .socialmediaImage{
    width: 35px;
    height: auto;
    margin-left: 20px;
  }

  

  .Navsearch{
    width: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,gCAAgC;AAClC;;EAEE;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,yBAAyB;;EAE3B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;;;EAGrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;;;EAGpB;EACA;IACE,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;;;EAIA;IACE,WAAW;EACb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');\n.exo-2 {\n  font-family: \"Exo 2\", sans-serif;\n}\n\n  header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 7vh;\n    background-color: #000000;\n   \n  }\n\n  .flex{\n    display: flex;\n    justify-content: center;\n    align-items: center; \n  }\n  .contactsFont{\n    color: white;\n    font-size: 15px;\n    font-weight: 100;\n  } \n\n  .contacts{\n    display: flex;\n    align-items: center;\n  \n   \n  }\n\n  .socialMedia{\n    display: flex;\n    align-items: center;\n    margin-right: 80px;\n   \n   \n  }\n  .contact_Email{\n    margin-inline: 100px;\n  }\n  \n  .contactImage{\n    width: 20px;\n    padding-inline: 10px;\n  }\n\n  .socialmediaImage{\n    width: 35px;\n    height: auto;\n    margin-left: 20px;\n  }\n\n  \n\n  .Navsearch{\n    width: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
