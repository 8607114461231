import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Carousel,
  Container,
  Image,
  Stack,
} from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import location from "../../../src/Resources/Assets/location.png";
import phone from "../../../src/Resources/Assets/phone.png";
import doctor from "../../Resources/Assets/Vector.png";
import banner from "../../Resources/Assets/banner.png";
import banner2 from "../../Resources/Assets/banner2.png";
import banner3 from "../../Resources/Assets/banner3.png";
import report from "../../Resources/Assets/image 10.png";
import checkup from "../../Resources/Assets/image 8.png";
import collection from "../../Resources/Assets/image 9.png";
import { useInit as useBookingLabTestInit } from "../BookingLabTest/Bookinglabtest.hook.js";
import { useInit as useHealthPackageInit } from "../HealthPackage/HealthPackage.Hooks.js";

import { HomeStyles } from "./Home.styles";

const useStyles = createUseStyles(HomeStyles);

const Homepage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleButtonClick = (id, type) => {
    navigate(`/Appointment-Booking/${id}/${type}`);
  };

  const handleTestBookButton = (id, type) => {
    navigate(`/Appointment-Booking/${id}/${type}`);
  };

  const handleScanningClick = () => {
    navigate("/book-scanning");
  };

  const handlePackageViewAllClick = () => {
    navigate("/health-packages");
  };

  const handleTestsViewAllClick = () => {
    navigate(`/book-test`);
  };

  const { state: healthPackageState, actions: healthPackageActions } =
    useHealthPackageInit();
  useEffect(() => {
    healthPackageActions.getHelathpackageAction();
  }, []);

  const packages = (
    healthPackageState.healthPackage?.packages?.length > 0
      ? healthPackageState.healthPackage.packages
      : []
  ).slice(0, 4);

  const { state: bookingLabTestState, actions: bookingLabTestActions } =
    useBookingLabTestInit();
  useEffect(() => {
    bookingLabTestActions.getBookinglabtestAction();
  }, []);

  const labTests = (
    bookingLabTestState.Bookinglabtest?.tests?.length > 0
      ? bookingLabTestState.Bookinglabtest.tests
      : []
  ).slice(0, 6);

  const [openAccordion, setOpenAccordion] = useState(null);

  const handleAccordionChange = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <Container fluid className={classes.Container}>
      <Stack direction="horizontal" className={classes.HompageHeadercontainer}>
        <Stack className={classes.Headerheading}>
          <h1 className={classes.Headerh1}>Polo Scan and Diagnostics</h1>
          <div className={classes.Headerp}>
            <ul className={classes.Headerul}>
              <li className={classes.Headerli}>
                We are dedicated to providing exceptional diagnostic services.
              </li>
              <li className={classes.Headerli}>
                Diagnostic services with precision, care, and expertise.
              </li>
              <li className={classes.Headerli}>
                Ensure accurate and timely diagnoses for all our patients.
              </li>
              <li className={classes.Headerli}>
                Our state-of-the-art facilities, coupled with a team of
                experienced professionals
              </li>
            </ul>
          </div>
        </Stack>
        <Stack className={classes.HompageHeaderimage}>
          <Image src={banner} alt="Banner" className={classes.ImageStyle} />
        </Stack>
      </Stack>

      <Stack className={classes.HealthPackagescontainer}>
        <h2 className={classes.HealthPackageh2}>Health Packages</h2>
        
        <p className={classes.HealthPackagep}>
          Take control of your health with our tailored health packages.
          Schedule your health package today for comprehensive and proactive
          care. Book a health package now and prioritize your well-being!
        </p>
        {packages.length > 0 && (
        <Button
          className={classes.viewAllButton}
          onClick={handlePackageViewAllClick}
        >
          View All
        </Button>
        )}
        
        <Stack className={classes.gridContainer}>
          {packages.map((pkg, index) => (
            <Stack key={index} className={classes.gridItem}>
              <Stack className={classes.packagecontainer}>
                <Stack className={classes.packageContent}>
                  <p>{pkg.name}</p>
                  <p>₹{pkg.price}</p>
                  <Button
                    className={classes.buttonStyle}
                    onClick={() => handleButtonClick(pkg.id, "package")}
                  >
                    Book Now
                  </Button>
                </Stack>
              </Stack>
              <Stack className={classes.accordionWrapper}>
                <Accordion
                  activeKey={openAccordion === index ? "0" : null}
                  onSelect={() => handleAccordionChange(index)}
                >
                  <Accordion.Item
                    eventKey="0"
                    className={classes.accordionMain}
                  >
                    <Accordion.Header className={classes.accordionHeader}>
                      <h>Tests Cover Under This Packages</h>
                    </Accordion.Header>
                    <Accordion.Body className={classes.accordionBody}>
                      {pkg.tests.map((test, index) => (
                          <span>{test.name},</span>  
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Stack>
            </Stack>
          ))}
        </Stack>
        
      </Stack>

      <Stack className={classes.scanningcontainer}>
        <h3 className={classes.scanningh3}>Book For Scanning</h3>
        <Carousel
          data-bs-theme="dark"
          style={{ width: "100%" }}
          controls={false}
        >
          <Carousel.Item key={1}>
            <div className={classes.CarouselItem}>
              <div className={classes.CarouselCaption}>
                <h5 className={classes.Carouselh5}>CT Scanning</h5>
                <p className={classes.Carouselp}>
                  All Scanning Under Reasonable Price
                </p>
                <button
                  className={classes.Carouselbutton}
                  onClick={handleScanningClick}
                >
                  Book Now
                </button>
              </div>
              <div className={classes.Carouselimgcontainer}>
                <img
                  className={classes.Carouselimg}
                  src={banner}
                  alt="Slide 1"
                />
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item key={2}>
            <div className={classes.CarouselItem}>
              <div className={classes.CarouselCaption}>
                <h5 className={classes.Carouselh5}>Ultra Sound</h5>
                <p className={classes.Carouselp}>
                  All Scanning Under Reasonable Price
                </p>
                <button className={classes.Carouselbutton}
                onClick={handleScanningClick}>Book Now</button>
              </div>
              <div className={classes.Carouselimgcontainer}>
                <img
                  className={classes.Carouselimg}
                 
                  src={banner3}
                  alt="Slide 2"
                />
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item key={3}>
            <div className={classes.CarouselItem}>
              <div className={classes.CarouselCaption}>
                <h5 className={classes.Carouselh5}>Digital X ray</h5>
                <p className={classes.Carouselp}>
                  All Scanning Under Reasonable Price
                </p>
                <button className={classes.Carouselbutton}
                onClick={handleScanningClick}>Book Now</button>
              </div>
              <div className={classes.Carouselimgcontainer}>
                <img
                  className={classes.Carouselimg}
                  
                  src={banner2}

                  alt="Slide 3"
                />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </Stack>

      <Stack className={classes.bookLabTestContainer}>
        <h2 className={classes.bookLabTestTitle}>Book Lab Tests</h2>
        <p className={classes.bookLabTestDescription}>
          Ensure your health with our accurate and reliable lab testing
          services. Schedule your lab test today for prompt and <br />
          professional results. Book a lab test now and take charge of your
          health!
        </p>
        {labTests.length > 0 && (
        <Button
          className={classes.viewAllButton}
          onClick={handleTestsViewAllClick}
        >
          View All
        </Button>
        )}
        
        <div className={classes.labtestontainer}>
          {labTests.map((test, index) => (
            <div key={index} className={classes.Labtestitem}>
              <h3 className={classes.itemTitle}>{test.name}</h3>
              <p className={classes.itemPrice}>{test.price}</p>
              <button
                className={classes.bookNowButton}
                onClick={() => handleTestBookButton(test.id, "Test")}
              >
                Book Now
              </button>
            </div>
          ))}
        </div>
      </Stack>

      <Stack className={classes.contactContainer}>
        <Stack className={classes.contactInfo}>
          <h3 className={classes.contactText}>Contact Us</h3>
          <Stack className={classes.contactDetails}>
            <div className={classes.ContactCompanyName}>
              <p className={classes.contactname}>
                Polo Scan and <br /> Diagnostics
              </p>
            </div>
            <div className={classes.ContactCompanyAddress}>
              <img
                className={classes.LocationIcon}
                src={location}
                alt="Location Icon"
              />
              <div className={classes.contactAddress}>
                <h4>Address</h4>
                <p>
                  H.No. 10-3-105 Lakshmi Nagar, Phase-2, R.L. Nagar,  <br />  Keesara ,Medchal Dist <br />{" "}
                  Hyderabad, Telangana 500083
                </p>
              </div>
            </div>
            <div className={classes.ContactCompanyPhoneNumber}>
              <img
                className={classes.LocationIcon}
                src={phone}
                alt="Phone Icon"
              />
              <div className={classes.contactAddress}>
                <h4>Phone</h4>
                <p>+91 70365 02011</p>
              </div>
            </div>
          </Stack>
        </Stack>
      </Stack>

      <Stack>
        <h2 className={classes.HomeSampleh}>Home Sample Available</h2>
        <Stack className={classes.HomeSamplecontainer}>
          <Stack className={classes.item}>
            <img className={classes.Sampleimg} src={doctor} alt="First slide" />
            <span className={classes.text}>Find a Test</span>
          </Stack>
          <Stack className={classes.item}>
            <img
              className={classes.Sampleimg}
              src={checkup}
              alt="First slide"
            />
            <span className={classes.text}>Buy Health Checkup</span>
          </Stack>
          <Stack className={classes.item}>
            <img
              className={classes.Sampleimg}
              src={collection}
              alt="First slide"
            />
            <span className={classes.text}>Home Sample Collection</span>
          </Stack>
          <Stack className={classes.item}>
            <img
              className={classes.SampleCollectionimg}
              src={report}
              alt="First slide"
            />
            <span className={classes.text}>Download Reports</span>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Homepage;
