import bannerimg from "../../Resources/Assets/Rectangle70.png";
import { Theme } from "../../Theme";

export const HomeStyles = {
  Container: {
    backgroundColor: "transparent",
    paddingLeft: "0%",
    paddingRight: "0%",
  },
  HompageHeadercontainer: {
    backgroundColor: Theme.color.primary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "595px",
    marginTop:"16px",

    "@media (max-width: 768px)": {
      flexDirection: "column",
      height: "auto",
    },
    "@media (max-width: 480px)": {
      flexDirection: "column",
      height: "auto",
      
    },
  },
  Headerheading: {
    width: "50%",
    color: Theme.color.white,
    display: 'flex',
    padding: "20px",
    justifyContent: 'center',
    "@media (max-width: 768px)": {
      width: "100%",
      textAlign: "center",
    },
    "@media (max-width: 480px)": {
      flexDirection: "column",
      height: "auto",
      
    },
  },
  Headerul: {
    marginLeft: "100px",
    "@media (max-width: 480px)": {
      marginLeft:"0",
    },
  },

  Headerli: {
    margin: "20px 0px",

  },
  Headerh1: {
    ...Theme.textFK2DW800S60,
    paddingLeft:"8rem",
    "@media (max-width: 768px)": {
      ...Theme.textFK2DW800S45,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFK2DW800S60,
      padding:"0",
    },
  },
  Headerp: {
    ...Theme.textFHeeboW400S18,
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
    },
  },
 
  HompageHeaderimage: {
    width: "45%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 768px)": {
      width: "100%",
      marginTop: "20px",
    },
  },
  ImageStyle: {
    maxWidth: "100%",
    height: "100%",
    objectFit: "cover",
  },

  HealthPackagesconatiner: {
    width: "100%",
    textAlign: "center",
    padding: "20px",
    "@media (max-width: 768px)": {
      padding: "10px",
    },
  },
  HealthPackagescontainer: {
    padding: "30px 30px 0px 30px",
    margin: "30px 30px 0px 30px",
    display: "flex",
    justifyContent: "center",
  },
  HealthPackageh2: {
    ...Theme.textFK2DW800S45,
    paddingBottom:"1%",
    "@media (max-width: 768px)": {
      ...Theme.textFK2DW800S45,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFK2DW800S45,
      
    },
  },
  HealthPackagep: {
    ...Theme.textFHeeboW400S18,
    textAlign:"center",
    paddingLeft:"11rem",
    paddingRight:"11rem",
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
      paddingLeft:"0",
      paddingRight:"0",
    },
  },

  viewAllButton:{
    backgroundColor: "white",
    color: "black",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    marginBottom: "15px",
    marginRight:"5%",
    alignSelf: "flex-end", 
    "&:hover": {
      backgroundColor: "#1131A2", 
    },
  },
  gridContainer: {
    gap: "30px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "1%",
    paddingLeft:"6rem",
    paddingRight:"6rem",
    
    "@media (max-width: 480px)": {
      paddingLeft:"0",
      paddingRight:"0",
    },
  },
  gridItem: {
    flex: "1 1 40%",
    maxWidth: "40%",
    marginBottom: "20px",
    "@media (max-width: 768px)": {
      flex: "1 1 100%",
      maxWidth: "100%",
    },
  },
  packagecontainer: {
    width: "100%",
    height: "4rem",
    border: "1px solid #000",
    borderRadius: "25px 0px 0px 25px",
    display: "flex",
    flex:"none",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)",
    "@media (max-width: 768px)": {
      width: "100%",
      height: "3.5rem",
    },
    "@media (max-width: 480px)": {
      width: "105%",
      height: "4.5rem",
    },
  },
  packageContent: {
    ...Theme.textFHeeboW500S22,
    fontWeight:"bold",
    paddingLeft: "4%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW500S14,
      lineHeight:"normal",
    },
  },
  buttonStyle: {
    ...Theme.textFHeeboW500S25,
    textAlign:"end",
    width: "11rem",
    height: "100%",
    border: "1px solid #000",
    borderRadius: "30px 0px 0px 30px",
    backgroundColor: "#00CC45",
    "@media (max-width: 768px)": {
      width: "40%",
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
      width: "50%",
      
    },
  },
  accordionWrapper: {
    width: "90%",
    top: "-1%",
    display: "flex",
    flex: "0 1 auto",
    marginLeft: "9%",
    borderRadius: "0px 0px 0px 25px",
    overflow: "hidden",
    border: "1px solid #000",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)",
    "@media (max-width: 768px)": {
      width: "100%",
    },
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  accordionHeader: {
    backgroundColor: "#1131A2 !important",
    color: "#fff !important",
  },
  accordionBody: {
    backgroundColor: "#E8E8E8",
  },



  scanningcontainer: {
    width: "100%",
    marginTop:"20px",
    marginBottom:"30px"
  },
  scanningh3: {
    ...Theme.textFK2DW800S45,
    marginBottom:"30px",
    
  },
  CarouselItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    backgroundColor: Theme.color.secondary,
    "@media (max-width: 480px)": {
      
      display: "flex",
      flexDirection: "column-reverse",
      backgroundColor: Theme.color.secondary,
    }
  },
  Carouselimgcontainer: {
    width: "60%",
    marginRight:"50px",


  },
  Carouselimg: {

    height: "auto",
    margin: "20px",
    objectFit: "cover",
  },

  CarouselCaption: {
    left: "0",
    color: "#fff",
    width: "40%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "100px",
  },
  Carouselh5: {
    ...Theme.textFK2DW800S50,
  },
  Carouselp: {
    ...Theme.textFHeeboW500S18,
  },


  Carouselbutton: {
    width: '75%',
    height: '2.5rem',
    backgroundColor: '#00CC45',
    border: "0 solid #000",
    cursor: "pointer",
    borderRadius: "5px 5px 5px 5px",
  },
  "@media (max-width: 1200px)": {
    scanningh3: {
      fontSize: "1.8rem",
    },
    Carouselimgcontainer: {
      width: "100%",
      height: "auto",
    },
    Carouselimg: {
      width: "100%",
      height: "auto",
    },
    CarouselCaption: {
      left: "auto",
      right: "0",
      bottom: "3rem",
      textAlign: "center",
    },
    Carouselh5: {
      ...Theme.textFK2DW800S50,
    },
    Carouselp: {
      ...Theme.textFHeeboW500S18,
    },
  },
  bookLabTestTitle: {
    ...Theme.textFK2DW800S45,
    margin: "2%",
  },
  bookLabTestDescription: {
    ...Theme.textFHeeboW400S18,
    textAlign:"center",
    padding:"0px 20px",
  },
  labtestontainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "35px",
  },
  Labtestitem: {
    flex: "1 1 calc(33.333% - 20px)",
    maxWidth: "calc(30% - 20px)",
    border: "2px solid #D9D9D9",
    borderRadius: "10px",
    paddingTop: "20px",
    paddingBottom: "20px",
    transition: "transform 0.3s, box-shadow 0.3s",
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      transform: "scale(1.05)",
    },
    "@media (max-width: 768px)": {
      flex: "1 1 calc(50% - 20px)",
      maxWidth: "calc(50% - 20px)",
    },
    "@media (max-width: 480px)": {
      flex: "1 1 calc(100% - 20px)",
      maxWidth: "calc(100% - 20px)",
    },
  },
  itemTitle: {
    ...Theme.textFK2DW800S20,
  },
  itemPrice: {
    ...Theme.textFHeeboW500S18,
    textAlign: "center",
  },
  bookNowButton: {
    ...Theme.textFHeeboW500S14,
    backgroundColor: "transparent",
    border: "1px solid #00CC45",
    color: "black",
    borderRadius: "5px",
    padding: "10px 60px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#00CC45",
      color: "#fff",
    },
  },
  contactContainer: {
    marginTop: "5%",
    marginBottom: "5%",
    color: Theme.color.white,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${bannerimg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  contactInfo: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    height: "399px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    "@media (max-width: 768px)": {
      width: "100%",
      padding: "10px",
      height: "70%",
    },
  },
  contactText: {
    ...Theme.textFK2DW800S45,
    marginTop: '2%',
    "@media (max-width: 768px)": {
      fontSize: '24px',
    },
  },
  contactDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "2%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  contactname: {
    ...Theme.textFK2DW800S50,
    "@media (max-width: 768px)": {
      fontSize: '24px',
    },
  },
  contactAddress: {
    ...Theme.textFPoppinsW400S20,
    marginBottom: '10px',
    textAlign:"left",
    "@media (max-width: 768px)": {
      fontSize: '16px',
    },
  },
  contactPhone: {
    ...Theme.textFPoppinsW400S18,
    marginTop: '4rem',
    "@media (max-width: 768px)": {
      fontSize: '14px',
    },
  },
  ContactCompanyName: {
    width: "40%",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  ContactCompanyAddress: {
    width: "30%",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  ContactCompanyPhoneNumber: {
    display: "flex",
    width: "30%",
    alignItems: "start",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  LocationIcon: {
    height: "45px",
    paddingRight: "1rem",
  },



  HomeSampleh: {
    ...Theme.textFK2DW800S45,
  },
  HomeSamplecontainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "20px",
    marginLeft: "6%",
    marginRight: "6%",
    marginTop: '2%',
    marginBottom: '4%',
    backgroundColor: "#f0f4ff",
    height: "313px",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "#000",
    justifyContent: "center",
    height: "100%",
  },
  Sampleimg: {
    height: "auto",
    maxHeight: "100%",
  },
  SampleCollectionimg: {
    height: "auto",
    maxHeight: "100%",
  },
  text: {
    ...Theme.textFHeeboW400S18,
  },
  "@media (max-width: 768px)": {
    HomeSamplecontainer: {
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '5%',
      marginRight: '5%',
      height: 'auto', 
    }
  },
}