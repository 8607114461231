import { Theme } from "../../Theme";

export const HealthStyles = {
  Container: {
    backgroundColor: "transparent",
    paddingLeft: "0%",
    paddingRight: "0%",
  },
  HompageHeadercontainer: {
    backgroundColor: Theme.color.primary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "595px",
    marginTop: "1%",

    "@media (max-width: 768px)": {
      flexDirection: "column",
      height: "auto",
    },
    "@media (max-width: 480px)": {
      flexDirection: "column",
      height: "auto",
      padding: "10px",
    },
  },
  Headerheading: {
    width: "50%",
    color: Theme.color.white,
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "100%",
      textAlign: "center",
    },
  },
  Headerh1: {
    ...Theme.textFK2DW800S60,
    paddingLeft: "8rem",
    paddingBottom: "1.5rem",
    "@media (max-width: 768px)": {
      ...Theme.textFK2DW800S45,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFK2DW800S60,
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  Headerp: {
    ...Theme.textFHeeboW400S18,
    paddingLeft: "8rem",
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  HompageHeaderimage: {
    width: "45%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 768px)": {
      width: "100%",
      marginTop: "20px",
    },
  },
  ImageStyle: {
    maxWidth: "100%",
    height: "100%",
    objectFit: "cover",
  },
  HealthPackagescontainer: {
    padding: "60px",
  },
  HealthPackageh2: {
    ...Theme.textFK2DW800S45,
    paddingBottom: "1%",
    "@media (max-width: 768px)": {
      ...Theme.textFK2DW800S45,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFK2DW800S20,
    },
  },
  HealthPackagep: {
    ...Theme.textFHeeboW400S18,
    textAlign: "center",
    paddingLeft: "11rem",
    paddingRight: "11rem",
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  gridContainer: {
    gap: "30px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "1%",
    paddingLeft: "6rem",
    paddingRight: "6rem",
    "@media (max-width: 480px)": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  gridItem: {
    flex: "1 1 40%",
    maxWidth: "40%",
    marginBottom: "20px",
    "@media (max-width: 768px)": {
      flex: "1 1 100%",
      maxWidth: "100%",
    },
  },
  packagecontainer: {
    width: "100%",
    height: "4rem",
    border: "1px solid #000",
    borderRadius: "25px 0px 0px 25px",
    display: "flex",
    flex: "none",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)",
    "@media (max-width: 768px)": {
      width: "100%",
      height: "3.5rem",
    },
    "@media (max-width: 480px)": {
      width: "105%",
      height: "4.5rem",
    },
  },
  packageContent: {
    ...Theme.textFHeeboW500S22,
    fontWeight: "bold",
    paddingLeft: "4%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW500S14,
      lineHeight:"normal",
    },
  },
  buttonStyle: {
    ...Theme.textFHeeboW500S25,
    textAlign: "end",
    width: "11rem",
    height: "100%",
    border: "1px solid #000",
    borderRadius: "30px 0px 0px 30px",
    backgroundColor: "#00CC45",
    "@media (max-width: 768px)": {
      width: "40%",
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
      width: "50%",
    },
  },
  accordionWrapper: {
    width: "90%",
    top: "-1%",
    display: "flex",
    flex: "0 1 auto",
    marginLeft: "9%",
    borderRadius: "0px 0px 0px 25px",
    overflow: "hidden",
    border: "1px solid #000",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.6)",
    "@media (max-width: 768px)": {
      width: "100%",
    },
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  accordionHeader: {
    backgroundColor: "#1131A2 !important",
    color: "#fff !important",
  },
  accordionBody: {
    backgroundColor: "#E8E8E8",
  },

  HomeSampleh: {
    ...Theme.textFK2DW800S45,
  },
  HomeSamplecontainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-end",
    padding: "20px",
    marginLeft: "9%",
    marginRight: "9%",
    backgroundColor: "#f0f4ff",
    marginTop: "2%",
    marginBottom: "4%",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "#000",
    height: "10%",
  },
  Sampleimg: {
    height: "10%",
  },
  SampleCollectionimg: {
    height: "9%",
  },
  text: {
    ...Theme.textFHeeboW500S18,
  },
  '@media (max-width: 768px)': {
    HomeSamplecontainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    item: {
      flexBasis: '80%',
      marginBottom: '20px',
    },
    Sampleimg: {
      maxWidth: "100px",
    },
    SampleCollectionimg: {
      maxWidth: "100px",
    },
  },
  '@media (max-width: 480px)': {
    HomeSampleh: {
      ...Theme.textFK2DW800S45,
      fontSize: '1.5rem',
    },
    item: {
      flexBasis: '100%',
    },
    Sampleimg: {
      maxWidth: "80px",
    },
    SampleCollectionimg: {
      maxWidth: "80px",
    },
    text: {
      ...Theme.textFHeeboW500S18,
      fontSize: '1rem',
    },
  }
};
