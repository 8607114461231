import { useReducer } from 'react';
import { BookingScanningReducer } from './Bookingscanning.reducer';
import { BookingScanningInitialState } from './Bookingscanning.models';
import { getBookingScanningList } from './Bookingscanning.services';
import { BOOKINGSCANNING_ACTIONS } from './Bookingscanning.actions';

export const useInit = () => {
    const [state, dispatch] = useReducer(BookingScanningReducer, BookingScanningInitialState);
    const actions = BookingScanningActions(dispatch);

    return { state, actions };
};

const BookingScanningActions = (dispatch) => {
    const actions = {
        getBookingScanningAction: async () => {
            dispatch({
                type: BOOKINGSCANNING_ACTIONS.SET_LOADING,
                data: true
            });
            const response = await getBookingScanningList();
            if (response.statusCode === 200) {
                dispatch({
                    type: BOOKINGSCANNING_ACTIONS.SET_BOOKINGSCANNING,
                    data: response.BookingScanning
                });
            } else {
                dispatch({
                    type: BOOKINGSCANNING_ACTIONS.SET_ERROR,
                    data: response.error
                });
            }

            dispatch({
                type: BOOKINGSCANNING_ACTIONS.SET_LOADING,
                data: false
            });
        }
    };

    return actions;
};