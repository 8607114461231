import React, { useState } from "react";
import { Accordion, Button, Container, Image, Stack } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import doctor from "../../Resources/Assets/Vector.png";
import report from "../../Resources/Assets/image 10.png";
import checkup from "../../Resources/Assets/image 8.png";
import collection from "../../Resources/Assets/image 9.png";
import banner from "../../Resources/Assets/packages.png";
import { HealthStyles } from "./Health.styles.js";
import { useInit } from "./HealthPackage.Hooks.js";



const useStyles = createUseStyles(HealthStyles);

const HealthPackage = () => {
  const classes = useStyles();
  const navigate = useNavigate();


  const { state, actions } = useInit();
  React.useEffect(() => {
    actions.getHelathpackageAction();
  }, []);
  console.log('STATE::', state);

  const handleButtonClick = (id) => {
    const type = "package";  
    navigate(`/Appointment-Booking/${id}/${type}`);
  };
  
  const packages = state.healthPackage?.packages?.length > 0 ? state.healthPackage?.packages : []


  const [openAccordion, setOpenAccordion] = useState(null);

  const handleAccordionChange = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <Container fluid className={classes.Container}>
      <Stack direction="horizontal" className={classes.HompageHeadercontainer}>
        <Stack className={classes.Headerheading}>
          <h1 className={classes.Headerh1}>
            Book a health package <br /> now and invest in <br /> your health!
          </h1>
          <div className={classes.Headerp}>
            <p>
              Take charge of your well-being with our <br /> comprehensive
              health packages tailored to your <br /> needs. Schedule your
              health package today for a <br /> thorough assessment and
              proactive care.
            </p>
          </div>
        </Stack>
        <Stack className={classes.HompageHeaderimage}>
          <Image src={banner} alt="Banner" className={classes.ImageStyle} />
        </Stack>
      </Stack>

      <Stack className={classes.HealthPackagescontainer}>
        <h2 className={classes.HealthPackageh2}>Health Packages</h2>
        <p className={classes.HealthPackagep}>
          At Polo Scan and Diagnostic, we offer a range of comprehensive health
          packages designed to cater to your specific wellness <br /> needs. Our
          packages are carefully curated to provide you with a thorough
          assessment of your health and help you proactively <br /> manage
          your well-being.
        </p>
        <Stack className={classes.gridContainer}>
          {packages.map((pkg, index) => (
            <Stack key={index} className={classes.gridItem}>
              <Stack className={classes.packagecontainer}>
                <Stack className={classes.packageContent}>
                  <p>{pkg.name}</p> <p>₹{pkg.price}</p>
                  <Button className={classes.buttonStyle}
                  onClick={() => handleButtonClick(pkg.id)}>
                    Book Now
                  </Button>
                </Stack>
              </Stack>
              <Stack className={classes.accordionWrapper}>
                <Accordion
                  activeKey={openAccordion === index ? "0" : null}
                  onSelect={() => handleAccordionChange(index)}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className={classes.accordionHeader}>
                      <h>Tests Cover Under This Packages</h>
                    </Accordion.Header>
                    <Accordion.Body className={classes.accordionBody}>
                      {pkg.tests.map((test, index) => (
                          <span>{test.name},</span>  
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack>
        <h2 className={classes.HomeSampleh}>Home Sample Available</h2>
        <div className={classes.HomeSamplecontainer}>
          <div className={classes.item}>
            <img className="Samplelimg" src={doctor} alt="First slide" />
            <span className={classes.text}>Find a Test</span>
          </div>
          <div className={classes.item}>
            <img className="Sampleimg" src={checkup} alt="First slide" />
            <span className={classes.text}>Buy Health Checkup</span>
          </div>
          <div className={classes.item}>
            <img className="Sampleimg" src={collection} alt="First slide" />
            <span className={classes.text}>Home Sample Collection</span>
          </div>
          <div className={classes.item}>
            <img
              className="SampleCollectionimg"
              src={report}
              alt="First slide"
            />
            <span className={classes.text}>Download Reports</span>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

export default HealthPackage;
