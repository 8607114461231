import React from "react";
import { Container } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { TermsAndConditionsStyles } from "./TermsAndConditions.styles";

const useStyles = createUseStyles(TermsAndConditionsStyles);

const TermsAndConditions = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <h2 className={classes.title}>Terms and Conditions</h2>

      <div className={classes.section}>
        <h3 className={classes.header}>*DISCLAIMER*</h3>
        <p>
          This service facilitates online fee payments to the Company through
          BillDesk (the "Payment Service Providers"). The Company makes <br/>no
          express or implied representations about the operation of the Payment
          Gateway beyond its specified purpose. Users agree that their <br/>use of
          this online payment service is entirely at their own risk. Online
          payment of fees does not constitute final admission to any courses <br/>of
          the Company. Separate admission formalities must be completed.
        </p>
        <p>
          Online payment of fees does not constitute final admission to any
          courses of the Company. Separate admission formalities must be<br/>
          completed.
        </p>
      </div>

      <div className={classes.section}>
        <h3 className={classes.header}>*LIMITATION OF LIABILITY*</h3>
        <ol>
          <li className={classes.li}>
            The Company, its affiliates, or employees shall not be liable for
            any direct, indirect, incidental, special, consequential, or
            exemplary <br/> damages, including but not limited to, loss of profits,
            goodwill, data, or other intangible losses arising from the use of
            the Payment <br/>Gateway service.
          </li>
          <li className={classes.li}>
            The Company assumes no liability for any monetary or other damage
            suffered due to any delay, failure, interruption, or corruption of<br/>
            data or other information transmitted during the use of the Payment
            Gateway service.
          </li>
          <li className={classes.li}>
            You shall indemnify and hold harmless the Company and its officers,
            directors, and employees from any claims or actions arising<br/> from
            your use of the Payment Gateway. The Company is not liable for any
            failures in the external link or for any fraud occurring at the<br/>
            payment gateway or the bank end.
          </li>
          <li className={classes.li}>
            You acknowledge that personal data, including debit/credit card or
            bank account details submitted during payment, is transmitted <br/>over
            the Internet and is susceptible to misuse, theft, and/or fraud. The
            Company has no control over such matters.
          </li>
          <li className={classes.li}>
            Although reasonable care is taken to protect against unauthorized
            use of information transmitted by you, the Company does not<br/>
            guarantee that the use of the payment gateway will not result in
            theft and/or unauthorized use of data over the Internet.
          </li>
        </ol>
      </div>

      <div className={classes.section}>
        <h3 className={classes.header}>
        *DEBIT/CREDIT CARD, BANK ACCOUNT DETAILS*
        </h3>
        <p>
          You may pay fees using a Debit/Credit Card or Internet Banking
          Account. By providing your Debit/Credit Card details for payment<br/>
          processing, you agree to the following:
        </p>
        <ul>
          <li>
            You are fully and lawfully entitled to use the specified
            Debit/Credit Card or Bank Account for transactions.
          </li>
          <li>
            You are responsible for ensuring that all Debit/Credit Card or Bank
            Account details provided are accurate.
          </li>
          <li>
            You authorize the debit to the nominated Debit/Credit Card or Bank
            Account for the settlement of bills selected by you, along with<br/>
            applicable fees.
          </li>
          <li>
            You are responsible for ensuring sufficient credit is available on
            your Debit/Credit Card or Bank Account at the time of payment to<br/>
            permit the settlement of selected bills and applicable fees.
          </li>
        </ul>
      </div>

      <div className={classes.section}>
        <h3 className={classes.header}>*REFUND POLICY*</h3>
        <p>
          In the event of any booking problems or other issues resulting in an
          uneven transaction, the Company will review the situation <br/>and process
          a refund if deemed appropriate. Users must report such issues promptly
          to enable the Company to address them in a timely<br/> manner.
        </p>
      </div>

      <p className={classes.bottomsection}>
        By proceeding with the payment process, you expressly agree to these
        terms and conditions.
      </p>
    </Container>
  );
};

export default TermsAndConditions;
