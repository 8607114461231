import { BOOKINGSCANNING_ACTIONS } from "./Bookingscanning.actions";

export const BookingScanningReducer = (state, action) =>{
  switch (action.type) {
      case BOOKINGSCANNING_ACTIONS.SET_LOADING:
          return {
              ...state,
              isLoading: action.data
          };
      case BOOKINGSCANNING_ACTIONS.SET_ERROR:
          return {
              ...state,
              error: action.data
          };

      case BOOKINGSCANNING_ACTIONS.SET_BOOKINGSCANNING:
          return {
              ...state,
              BookingScanning: action.data
          };
      default:
          return { ...state };
  }
}