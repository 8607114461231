import { BOOKINGLABTEST_ACTIONS } from "./Bookinglabtest.actions";

export const BookinglabtestReducer = (state, action) =>{
  switch (action.type) {
      case BOOKINGLABTEST_ACTIONS.SET_LOADING:
          return {
              ...state,
              isLoading: action.data
          };
      case BOOKINGLABTEST_ACTIONS.SET_ERROR:
          return {
              ...state,
              error: action.data
          };

      case BOOKINGLABTEST_ACTIONS.SET_BOOKINGLABTEST:
          return {
              ...state,
              Bookinglabtest: action.data
          };
      default:
          return { ...state };
  }
}