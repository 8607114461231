import { Theme } from "../../Theme";

export const AppointmentBookingstyles = {
  BookAppointmentContainer: {
    backgroundColor: "transparent",
  },
  HompageHeadercontainer: {
    backgroundColor: Theme.color.primary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "595px",
    marginTop: "16px",

    "@media (max-width: 768px)": {
      flexDirection: "column",
      height: "auto",
    },
    "@media (max-width: 480px)": {
      flexDirection: "column",
      height: "auto",
      
    },
  },
  Headerheading: {
    width: "50%",
    color: Theme.color.white,
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "100%",
      textAlign: "center",
    },
  },
  Headerh1: {
    paddingBottom:"30px",
    paddingLeft:"8rem",
    ...Theme.textFK2DW800S60,
    "@media (max-width: 768px)": {
      ...Theme.textFK2DW800S45,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFK2DW800S60,
      paddingLeft:"0rem",
    },
  },
  Headerp: {
    ...Theme.textFHeeboW400S18,
    textAlign:"left",
    paddingLeft:"8rem",
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
    },
  },
  HompageHeaderimage: {
    width: "45%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 768px)": {
      width: "100%",
      marginTop: "20px",
    },
  },
  ImageStyle: {
    maxWidth: "100%",
    height: "100%",
    objectFit: "cover",
  },

  formContainer: {
    width: "70%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  formTitle: {
    ...Theme.textFK2DW800S45,
    marginBottom: "1.5rem",
    color: "#004d40",
    fontWeight: "bold",
    paddingTop: "3.5rem",
  },
  formGroup: {
    marginBottom: "1rem",
    padding: "16px",

    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  
  formControl: {
    borderRadius: "10px",
    backgroundColor: "#e0e0e0",
    border: "1px solid #ccc",
    padding: "0.5rem",
    gap: "1rem",
    Placeholder:"time",
    marginBottom: "0.5rem",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  formLabel: {
    fontWeight: "bold",
    marginRight:"1rem",
  },
  radioGroup: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  submitButtonContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "3.5rem",
  },
  submitButton: {
    backgroundColor: "#00c853",
    borderColor: "#00c853",
    borderRadius: "10px",
    width: "40rem",
    padding: "0.75rem",
    alignItems: "center",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  bookingtag:{
    fontSize:"0.8rem",
  }
};
