import { Theme } from "../../Theme";

export const BookingLabTeststyles = {
  Container: {
    backgroundColor: "transparent",
    paddingLeft: "0%",
    paddingRight: "0%",
  },
  HompageHeadercontainer: {
    backgroundColor: Theme.color.primary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "595px",
    marginBottom: "3.75rem",
    marginTop: "16px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      height: "auto",
    },
    "@media (max-width: 480px)": {
      flexDirection: "column",
      height: "auto",
    },
  },
  Headerheading: {
    width: "50%",
    color: Theme.color.white,
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "100%",
      textAlign: "center",
    },
  },
  Headerh1: {
    ...Theme.textFK2DW800S60,
    paddingLeft: "8rem",
    "@media (max-width: 768px)": {
      ...Theme.textFK2DW800S45,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFK2DW800S60,
      padding: "0",
    },
  },
  Headerp: {
    ...Theme.textFHeeboW400S18,
    paddingLeft: "8rem",
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },

    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  HompageHeaderimage: {
    width: "45%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 768px)": {
      width: "100%",
      marginTop: "20px",
    },
  },
  ImageStyle: {
    maxWidth: "100%",
    height: "100%",
    objectFit: "cover",
  },
  bookLabTestTitle: {
    ...Theme.textFK2DW800S45,
    margin: "1%",
  },
  bookLabTestDescription: {
    ...Theme.textFHeeboW400S18,
    textAlign: "center",
    paddingLeft: "11rem",
    paddingRight: "11rem",
    "@media (max-width: 768px)": {
      ...Theme.textFHeeboW400S18,
    },
    "@media (max-width: 480px)": {
      ...Theme.textFHeeboW400S18,
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  labtestontainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
    marginBottom: "3.75rem",
  },
  Labtestitem: {
    flex: "1 1 calc(33.333% - 20px)",
    maxWidth: "calc(30% - 20px)",
    border: "1px solid #ddd",
    borderRadius: "10px",
    padding: "20px",
    transition: "transform 0.3s, box-shadow 0.3s",
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      transform: "scale(1.05)",
    },
    "@media (max-width: 768px)": {
      flex: "1 1 calc(50% - 20px)",
      maxWidth: "calc(50% - 20px)",
    },
    "@media (max-width: 480px)": {
      flex: "1 1 calc(100% - 20px)",
      maxWidth: "calc(100% - 20px)",
    },
  },
  itemTitle: {
    ...Theme.textFK2DW800S20,
  },
  itemPrice: {
    ...Theme.textFHeeboW500S18,
    textAlign: "center",
  },
  bookNowButton: {
    ...Theme.textFHeeboW500S14,
    backgroundColor: "transparent",
    border: "1px solid #00CC45",
    color: "#00CC45",
    borderRadius: "5px",
    padding: "10px 20px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#00CC45",
      color: "#fff",
    },
  },
  HomeSampleh: {
    ...Theme.textFK2DW800S45,
  },
  HomeSamplecontainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-end",
    padding: "20px",
    marginLeft: "9%",
    marginRight: "9%",
    backgroundColor: "#f0f4ff",
    marginTop: "2%",
    marginBottom: "4%",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "#000",
    height: "10%",
  },
  Sampleimg: {
    height: "10%",
  },
  SampleCollectionimg: {
    height: "9%",
  },
  text: {
    ...Theme.textFHeeboW500S18,
  },

  "@media (max-width: 1200px)": {
    HomeSamplecontainer: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    item: {
      flexBasis: "45%",
    },
  },
  "@media (max-width: 768px)": {
    HomeSamplecontainer: {
      flexDirection: "column",
      alignItems: "center",
    },
    item: {
      flexBasis: "80%",
      marginBottom: "20px",
    },
    Sampleimg: {
      maxWidth: "100px",
    },
    SampleCollectionimg: {
      maxWidth: "100px",
    },
  },
  "@media (max-width: 480px)": {
    HomeSampleh: {
      ...Theme.textFK2DW800S45,
      fontSize: "1.5rem",
    },
    item: {
      flexBasis: "100%",
    },
    Sampleimg: {
      maxWidth: "80px",
    },
    SampleCollectionimg: {
      maxWidth: "80px",
    },
    text: {
      ...Theme.textFHeeboW500S18,
      fontSize: "1rem",
    },
  },
};
