import { Theme } from "../../Theme";

export const TermsAndConditionsStyles = {
  container: {
    paddingTop:"3rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    "@media (max-width: 768px)": {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    "@media (max-width: 576px)": {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  title: {
    ...Theme.textFK2DW800S45,
    textAlign: "center",
    marginBottom: "3rem",
  },
  section: {
    marginBottom: "3rem",
    ...Theme.textFHeeboW500S18,
  },
  header: {
    fontWeight: "bold",
    marginBottom:"2.5rem",
    "@media (max-width: 768px)": {
      marginBottom: "2rem",
    },
    "@media (max-width: 576px)": {
      marginBottom: "1.5rem",
    },
  },
  li:{
    paddingBottom:"1.5rem",
    "@media (max-width: 768px)": {
      marginBottom: "1rem",
    },
    "@media (max-width: 576px)": {
      marginBottom: "1rem",
    },
  },
  bottomsection:{
    ...Theme.textFHeeboW500S18,
    marginBottom:"3rem",
  }
};
