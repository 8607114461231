import React from "react";
import { createUseStyles } from "react-jss";
import { Container, Image, Stack } from "react-bootstrap";
import { Bookingscanningstyles } from "./BookingScanning.styles.js";
import banner from "../../Resources/Assets/scan.png";
import doctor from "../../Resources/Assets/Vector.png";
import checkup from "../../Resources/Assets/image 8.png";
import collection from "../../Resources/Assets/image 9.png";
import report from "../../Resources/Assets/image 10.png";
import { useInit } from "./Bookingscanning.hook.js";
import { useNavigate } from "react-router-dom";

const useStyles = createUseStyles(Bookingscanningstyles);

const BookingScanning = () => {
  const classes = useStyles();
  const navigate = useNavigate();


  const handleButtonClick = (id) => {
    const type = "Test";  
    navigate(`/Appointment-Booking/${id}/${type}`);
  };
  
  const { state, actions } = useInit();
  React.useEffect(() => {
    actions.getBookingScanningAction();
  }, []);
  console.log("STATE::", state);
  const Scanning =
    state.BookingScanning?.scans?.length > 0
      ? state.BookingScanning?.scans
      : [];

  return (
    <Container fluid className={classes.Container}>
      <Stack direction="horizontal" className={classes.HompageHeadercontainer}>
        <Stack className={classes.Headerheading}>
          <h1 className={classes.Headerh1}>
            Book a scan now and prioritize your health!{" "}
          </h1>
          <div className={classes.Headerp}>
            <p>
              Experience precise and comprehensive <br /> diagnostic imaging
              with our state-of-the-art <br /> scanning services. Schedule your
              scan today for
              <br />
              accurate results and peace of mind
            </p>
          </div>
        </Stack>
        <Stack className={classes.HompageHeaderimage}>
          <Image src={banner} alt="Banner" className={classes.ImageStyle} />
        </Stack>
      </Stack>

      <Stack className={classes.bookscanContainer}>
        <h className={classes.bookscanTitle}>Book Scannings</h>
        <p className={classes.bookscanDescription}>
          Get precise and detailed diagnostic imaging with our advanced scanning
          services. Schedule your scan today for accurate <br /> and timely
          results. Book a scan now and prioritize your health!
        </p>
        <div className={classes.scanningcontainer}>
          {Scanning.map((scans, index) => (
            <div key={index} className={classes.scanningitem}>
              <h3 className={classes.itemTitle}>{scans.name}</h3>
              <p className={classes.itemPrice}>{scans.price}</p>
              <button
                className={classes.bookNowButton}
                onClick={() => handleButtonClick(scans.id)}
              >
                Book Now
              </button>
            </div>
          ))}
        </div>
      </Stack>

      <Stack>
        <h className={classes.HomeSampleh}>Home Sample Available</h>
        <div className={classes.HomeSamplecontainer}>
          <div className={classes.item}>
            <img className="Samplelimg" src={doctor} alt="First slide" />
            <span className={classes.text}>Find a Test</span>
          </div>
          <div className={classes.item}>
            <img className="Sampleimg" src={checkup} alt="First slide" />
            <span className={classes.text}>Buy Health Checkup</span>
          </div>
          <div className={classes.item}>
            <img className="Sampleimg" src={collection} alt="First slide" />
            <span className={classes.text}>Home Sample Collection</span>
          </div>
          <div className={classes.item}>
            <img
              className="SampleCollectionimg"
              src={report}
              alt="First slide"
            />
            <span className={classes.text}>Download Reports</span>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

export default BookingScanning;
