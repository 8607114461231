import { HttpClient } from '../../Common/HttpClient/HttpClient'

export const getBookinglabtestList = async () => {
    try {
        const response = await HttpClient.get('/test/lab');
        if (response.status === 200) {
            return {
                statusCode: 200,
                Bookinglabtest: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Somthing want wrong please try again'
            };
        }
    } catch (error) {
        console.log('Error', error);
        return {
            statusCode: 404,
            error: error.message
        };
    }
};

