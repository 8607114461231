import { useReducer } from 'react';
import { BookinglabtestReducer } from './Bookinglabtest.reducer';
import { BookinglabtestInitialState } from './Bookinglabtest.models';
import { getBookinglabtestList } from './Bookinglabtest.services';
import { BOOKINGLABTEST_ACTIONS } from './Bookinglabtest.actions';

export const useInit = () => {
    const [state, dispatch] = useReducer(BookinglabtestReducer, BookinglabtestInitialState);
    const actions = BookinglabtestActions(dispatch);

    return { state, actions };
};

const BookinglabtestActions = (dispatch) => {
    const actions = {
        getBookinglabtestAction: async () => {
            dispatch({
                type: BOOKINGLABTEST_ACTIONS.SET_LOADING,
                data: true
            });
            const response = await getBookinglabtestList();
            if (response.statusCode === 200) {
                dispatch({
                    type: BOOKINGLABTEST_ACTIONS.SET_BOOKINGLABTEST,
                    data: response.Bookinglabtest
                });
            } else {
                dispatch({
                    type: BOOKINGLABTEST_ACTIONS.SET_ERROR,
                    data: response.error
                });
            }

            dispatch({
                type: BOOKINGLABTEST_ACTIONS.SET_LOADING,
                data: false
            });
        }
    };

    return actions;
};