import { useReducer, useMemo } from 'react';
import { submitAppointmentForm, getTestsList, getHealthpackagesList } from './AppointmentBooking.services';
import { AppointmentBookingReducer } from './AppointmentBooking.reducer';
import { AppointmentBookingInitialState } from './AppointmentBooking.models';
import { APPOINTMENTBOOKING_ACTIONS } from './AppointmentBooking.actions';

export const useInit = () => {
  const [state, dispatch] = useReducer(AppointmentBookingReducer, AppointmentBookingInitialState);

  const actions = useMemo(() => ({
    submitAppointmentForm: async (formData) => {
      dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_LOADING, data: true });
      try {
        const response = await submitAppointmentForm(formData);
        if (response.statusCode === 200) {
          dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SUBMIT_FORM_SUCCESS, data: response.message });
        } else {
          dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SUBMIT_FORM_FAILURE, data: response.error });
        }
        return response;
      } catch (error) {
        dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SUBMIT_FORM_FAILURE, data: error.message });
        return { error: error.message };
      } finally {
        dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_LOADING, data: false });
      }
    },

    getTestsAction: async () => {
      dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_LOADING, data: true });
      try {
        const response = await getTestsList();
        if (response.statusCode === 200) {
          dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_TESTS, data: response.tests });
          dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_ERROR, data: null });
        } else {
          dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_ERROR, data: response.error });
        }
      } catch (error) {
        dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_ERROR, data: error.message });
      } finally {
        dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_LOADING, data: false });
      }
    },

    getHelathpackageAction: async () => {
      dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_LOADING, data: true });
      try {
        const response = await getHealthpackagesList(); 
        if (response.statusCode === 200) {
          dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_PACKAGES, data: response.packages });
          dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_ERROR, data: null });
        } else {
          dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_ERROR, data: response.error });
        }
      } catch (error) {
        dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_ERROR, data: error.message });
      } finally {
        dispatch({ type: APPOINTMENTBOOKING_ACTIONS.SET_LOADING, data: false });
      }
    }
    
  }), []);

  return { state, actions };
};
