import { APPOINTMENTBOOKING_ACTIONS } from "./AppointmentBooking.actions";

export const AppointmentBookingReducer = (state, action) => {
    switch (action.type) {
        case APPOINTMENTBOOKING_ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.data
            };
        case APPOINTMENTBOOKING_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.data
            };
        case APPOINTMENTBOOKING_ACTIONS.SET_BOOKINGSCANNING:
            return {
                ...state,
                AppointmentBooking: action.data
            };
        case APPOINTMENTBOOKING_ACTIONS.SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                message: action.data
            };
        case APPOINTMENTBOOKING_ACTIONS.SUBMIT_FORM_FAILURE:
            return {
                ...state,
                error: action.data
            };
        case APPOINTMENTBOOKING_ACTIONS.SET_TESTS:
            return {
                ...state,
                tests: action.data
            };
            case APPOINTMENTBOOKING_ACTIONS.SET_PACKAGES:
                return {
                    ...state,
                    packages: action.data
                };
            
        default:
            return { ...state };
    }
}