import { useReducer } from 'react';
import { HEALTH_PACKAGE_ACTIONS } from './HealthPackage.Action';
import { HealthPackageReducer } from './HealthPackage.Reducer';
import { getHealthPackageList } from './HealthPackage.Services';
import { HealthPackageInitialState } from './HealthPackage.models';

export const useInit = () => {
    const [state, dispatch] = useReducer(HealthPackageReducer, HealthPackageInitialState);
    const actions = HelathpackageActions(dispatch);

    return { state, actions };
};

const HelathpackageActions = (dispatch) => {
    const actions = {
        getHelathpackageAction: async () => {
            dispatch({
                type: HEALTH_PACKAGE_ACTIONS.SET_LOADING,
                data: true
            });
            const response = await getHealthPackageList();
            if (response.statusCode === 200) {
                dispatch({
                    type:HEALTH_PACKAGE_ACTIONS.SET_HEALTH_PACKAGE,
                    data: response.healthPackage
                });
            } else {
                dispatch({
                    type:HEALTH_PACKAGE_ACTIONS.SET_ERROR,
                    data: response.error
                });
            }

            dispatch({
                type: HEALTH_PACKAGE_ACTIONS.SET_LOADING,
                data: false
            });
        }
    };

    return actions;
};