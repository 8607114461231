import { Collapse } from "react-bootstrap";

export const navigationstyles = {
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "black",
    color: "white",
    padding: "10px 100px",
    fontSize: "18px",
  },
  phoneNumber: {
    display: "flex",
    alignItems: "center",
  },
  phoneIcon: {
    marginLeft: "5px",
    height:"1.3rem",
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  icons: {
    height: "32px",
    margin: "0px 10px",
  },
  navbarCustom: {
    backgroundColor: "white",
  },

  navbarCollapse: {
    display: "flex !important",
    flexGrow:"0",
    justifyCcontent: "space-around",
  },
  navbarMain: {
    display: "flex ",
    flexDirection: "column",
    paddingTop: "1rem",
    marginLeft:"8rem",
    "@media (max-width: 768px)": {
      marginLeft:"0",
    },
  },
  logoImg: {
    width: "100%",
    height: "100px",
  },
  searchFormRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "2%",
  },
  searchBar: {
    position: "relative",
    flex: "1",
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    position: "absolute",
    left: "10px",
    width: "20px",
    height: "20px",
  },
  navSearch: {
    width: "220%",
    paddingRight: "7rem",
    padding: "10px 40px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    boxSizing: "border-box",
    "@media (max-width: 480px)": {
      width: "120%",
      paddingRight: "0rem",
      padding: "10px 40px",
    },
  },
  
  navbarLinksRow: {
    display: "flex",
    paddingTop: "1.5rem",
    width: "100%",
  },
  navbarLinks: {
    display: "flex",
    justifyContent: "center",
    "& .nav-link": {
      color: "black",
      margin: "0 15px",
      "&.active": {
        color: "#00CC45",
      },
    },
  },
  contactButton: {
    backgroundColor: "#00CC45",
    marginLeft: "0",
    height: "3rem",
    marginRight: "30px",
    borderRadius: "10px",
    padding: "0px 20px",
    border: "none",
  },
  navlinkName: {
    fontSize: "18px",
    fontWeight: "500",
  },
  searchResultsDropdown: {
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: "4px",
    width: "105%",
    maxHeight: "200px",
    overflowY: "auto",
    zIndex: 1000,
    top: "100%",
    left: "0",
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  searchResultItem: {
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
};
