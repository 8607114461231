import { useReducer, useCallback } from 'react';
import { searchReducer } from './App.reducer';
import { SEARCH_ACTIONS } from './App.actions';
import { searchTests } from './App.services';
import { SearchIntialState } from './App.model';

export const useSearch = () => {
  const [state, dispatch] = useReducer(searchReducer, SearchIntialState);

  const performSearch = useCallback(async (query) => {
    dispatch({ type: SEARCH_ACTIONS.SET_LOADING, data: true });
    try {
      const response = await searchTests(query);
      if (response.statusCode === 200) {
        dispatch({ type: SEARCH_ACTIONS.SET_RESULTS, data: response.data });
        dispatch({ type: SEARCH_ACTIONS.SET_ERROR, data: null });
      } else {
        dispatch({ type: SEARCH_ACTIONS.SET_ERROR, data: response.error });
      }
    } catch (error) {
      dispatch({ type: SEARCH_ACTIONS.SET_ERROR, data: error.message });
    } finally {
      dispatch({ type: SEARCH_ACTIONS.SET_LOADING, data: false });
    }
  }, []);

  return {
    state,
    performSearch
  };
};
