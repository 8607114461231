import { HttpClient } from '../../Common/HttpClient/HttpClient';

export const submitAppointmentForm = async (formData) => {
  try {
    const response = await HttpClient.post('/booking',formData);
    console.log(formData)
    if (response.status === 201) {
      return {
        statusCode: 200,
        message: 'Appointment booked successfully',
        data: response.status
      };
    } else {
      return {
        statusCode: response.status,
        error: 'Something went wrong, please try again'
      };
    }
  } catch (error) {
    console.error('Error', error);
    return {
      statusCode: 500,
      error: error.message
    };
  }
};


export const getTestsList = async () => {
  try {
      const response = await HttpClient.get('/test');
      if (response.status === 200) {
          return {
              statusCode: 200,
              tests: response.data
          };
      } else {
          return {
              statusCode: response.status,
              error: 'Something went wrong, please try again'
          };
      }
  } catch (error) {
      console.error('Error', error);
      return {
          statusCode: 404,
          error: error.message
      };
  }
};

export const getHealthpackagesList = async () => {
  try {
    const response = await HttpClient.get('/package');
    if (response.status === 200) {
      return {
        statusCode: 200,
        packages: response.data
      };
    } else {
      return {
        statusCode: response.status,
        error: 'Something went wrong, please try again'
      };
    }
  } catch (error) {
    console.error('Error', error);
    return {
      statusCode: 404,
      error: error.message
    };
  }
};
