import { SEARCH_ACTIONS } from './App.actions';



export const searchReducer = (state, action) => {
  switch (action.type) {
    case SEARCH_ACTIONS.SET_LOADING:
      return {
        ...state,
        isLoading: action.data
      };
    case SEARCH_ACTIONS.SET_ERROR:
      return {
        ...state,
        error: action.data
      };
    case SEARCH_ACTIONS.SET_RESULTS: 
      return {
        ...state,
        results: action.data || [] 
      };
    default:
      return state;
  }
};
