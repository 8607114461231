import React, { useEffect, useState, useRef, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { createUseStyles } from "react-jss";
import { Link, useNavigate } from "react-router-dom";
import { useSearch } from "../../../App.hook";
import facebook from "../../../Resources/Assets/facebook.png";
import instagram from "../../../Resources/Assets/instagram.png";
import logo from "../../../Resources/Assets/polo-logo.png";
import search from "../../../Resources/Assets/search.png";
import phone from "../../../Resources/Assets/phone.png";
import twitter from "../../../Resources/Assets/twitter.png";
import { navigationstyles } from "./navigationbar.styles";

const useStyles = createUseStyles(navigationstyles);

function NavbarComponent() {
  const classes = useStyles();
  const [activeLink, setActiveLink] = useState("/");
  const [searchQuery, setSearchQuery] = useState("");
  const { state, performSearch } = useSearch();
  const navigate = useNavigate();
  const previousQuery = useRef("");

  useEffect(() => {
    if (searchQuery && searchQuery !== previousQuery.current) {
      const delayDebounceFn = setTimeout(() => {
        performSearch(searchQuery);
        previousQuery.current = searchQuery;
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchQuery, performSearch]);

  useEffect(() => {
    const storedActiveLink = localStorage.getItem("activeLink");
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  const handleNavLinkClick = (path) => {
    localStorage.setItem("activeLink", path);
    setActiveLink(path);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    performSearch(searchQuery);
  };

  const handleResultClick = (id, type) => {
    navigate(`/Appointment-Booking/${id}/${type}`);
  };

  return (
    <div>
      <div className={classes.topBar}>
        <div className={classes.phoneNumber}>
          <span>7036502011</span>
          <img
            className={classes.phoneIcon}
            src={phone}
            alt="phone Icon"
          />
        </div>
        <div className={classes.socialIcons}>
          <a
            href="https://www.facebook.com/profile.php?id=61560186608490"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={classes.icons} src={facebook} alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/?next=%2F&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={classes.icons} src={instagram} alt="Instagram" />
          </a>
          <a
            href="https://x.com/PoloDiagnostics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={classes.icons} src={twitter} alt="Twitter" />
          </a>
        </div>
      </div>
      <Navbar expand="lg" className={classes.navbarCustom}>
        <Container>
          <Navbar.Brand href="#">
            <Image src={logo} alt="Logo" className={classes.logoImg} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            id="navbarScroll"
            className={`navbarScrollCollapse `}
          >
            <div className={classes.navbarMain}>
              <div className={classes.searchFormRow}>
                <div className={classes.searchBar}>
                  <img
                    className={classes.searchIcon}
                    src={search}
                    alt="Search"
                  />
                  <form onSubmit={handleSearch} className={classes.searchForm}>
                    <input
                      className={classes.navSearch}
                      type="text"
                      placeholder="Search For Test"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </form>
                  {searchQuery &&
                    state.results &&
                    state.results.result &&
                    state.results.result.length > 0 && (
                      <div className={classes.searchResultsDropdown}>
                        {state.results.result.map((result) => (
                          <div
                            key={result.id}
                            className={classes.searchResultItem}
                            onClick={() =>
                              handleResultClick(result.id, result.type)
                            }
                          >
                            <span>
                              {result.name} - {result.price}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </div>
              <div className={classes.navbarLinksRow}>
                <Nav className={classes.navbarLinks}>
                  <Nav.Link
                    as={Link}
                    to="/"
                    className={`nav-link ${activeLink === "/" ? "active" : ""}`}
                    onClick={() => handleNavLinkClick("/")}
                  >
                    <h6 className={classes.navlinkName}>Home</h6>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/book-test"
                    className={`nav-link ${
                      activeLink === "/book-test" ? "active" : ""
                    }`}
                    onClick={() => handleNavLinkClick("/book-test")}
                  >
                    <h6 className={classes.navlinkName}>Book a Test</h6>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/book-scanning"
                    className={`nav-link ${
                      activeLink === "/book-scanning" ? "active" : ""
                    }`}
                    onClick={() => handleNavLinkClick("/book-scanning")}
                  >
                    <h6 className={classes.navlinkName}>Book For Scanning</h6>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/health-packages"
                    className={`nav-link ${
                      activeLink === "/health-packages" ? "active" : ""
                    }`}
                    onClick={() => handleNavLinkClick("/health-packages")}
                  >
                    <h6 className={classes.navlinkName}>Health Packages</h6>
                  </Nav.Link>
                </Nav>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarComponent;
