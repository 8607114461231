import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Form, Image, Stack } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { parsePath, useParams } from "react-router-dom";
import banner from "../../Resources/Assets/Appointment.png";
import { AppointmentBookingstyles } from "./Appointment.styles";
import { useInit } from "./AppointmentBooking.hook.js";
import axios from "axios";
import logo from "../../Resources/Assets/polo-logo.png";

const useStyles = createUseStyles(AppointmentBookingstyles);

const BookAppointment = () => {
  const { id, type } = useParams();
  const classes = useStyles();
  const { state, actions } = useInit();

  const razorpayKey = process.env.REACT_APP_RAZORPAY_KEY;

  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem("formData");
    return savedFormData
      ? JSON.parse(savedFormData)
      : {
          name: "",
          phone: "",
          age: "",
          date: "",
          time: "",
          bookingId: id,
          paymentMethod: "",
          sampleCollection: "",
          type: type,
          status: "active",
          order_id: "",
        };
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [alert, setAlert] = useState({ show: false, variant: "", message: "" });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [dateFocused, setDateFocused] = useState(false);
  const [datePlaceholder, setDatePlaceholder] = useState("dd/mm/yyyy");
  const [isChecked, setIsChecked] = useState(false);

  const handleDateChange = (e) => {
    const formattedDate = e.target.value;
    handleChange({ target: { name: "date", value: formattedDate } });
  };

  const handleDateFocus = () => setDateFocused(true);

  const handleDateBlur = (e) => {
    setDateFocused(false);
    if (!e.target.value) {
      setDatePlaceholder("dd/mm/yyyy");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) {
          console.error("ID is not provided");
          return;
        }

        if (type === "Test") {
          if (
            !Array.isArray(state.tests?.tests) ||
            state.tests.tests.length === 0
          ) {
            await actions.getTestsAction();
          }
        } else if (type === "package") {
          if (
            !Array.isArray(state.packages?.packages) ||
            state.packages.packages.length === 0
          ) {
            await actions.getHelathpackageAction();
          }
        }
        populateFormData();
      } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
      }
    };

    fetchData();
  }, [id, type, state.tests?.tests, state.packages?.packages]);

  const populateFormData = () => {
    if (type === "Test") {
      const foundTest = state.tests?.tests?.find(
        (test) => test.id === parseInt(id)
      );
      if (foundTest) {
        setFormData((prevState) => ({
          ...prevState,
          test: foundTest,
          testName: foundTest.name,
          testPrice: foundTest.price,
        }));
      } else {
        console.error("Test not found");
      }
    } else if (type === "package") {
      const foundPackage = state.packages?.packages?.find(
        (pkg) => pkg.id === parseInt(id)
      );
      if (foundPackage) {
        setFormData((prevState) => ({
          ...prevState,
          package: foundPackage,
          testName: foundPackage.name,
          testPrice: foundPackage.price,
        }));
      } else {
        console.error("Package not found");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "age" ? value.trim() : value.trim();
    let error = "";

    if (name === "phone" && (newValue.length < 10 || isNaN(newValue))) {
      error = "Enter a valid mobile number";
    } else if (name === "age" && (isNaN(newValue) || newValue <= 0)) {
      error = "Enter a valid age";
    }

    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        [name]: newValue,
      };
      validateForm(updatedFormData);
      return updatedFormData;
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));

    if (
      !newValue &&
      (name === "name" ||
        name === "phone" ||
        name === "age" ||
        name === "date" ||
        name === "time" ||
        name === "paymentMethod" ||
        name === "sampleCollection")
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required",
      }));
    }
  };

  const validateForm = (data) => {
    const { name, phone, age, date, time, paymentMethod, sampleCollection } =
      data;
    const isValid =
      name &&
      phone &&
      !errors.phone &&
      age &&
      date &&
      time &&
      paymentMethod &&
      sampleCollection &&
      isChecked;
    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm(formData);
  }, [formData, isChecked]);
  useEffect(() => {
    validateForm(formData);
  }, [formData]);

  const clearFormData = () => {
    setFormData({
      name: "",
      phone: "",
      age: "",
      date: "",
      time: "",
      bookingId: id,
      paymentMethod: "",
      sampleCollection: "",
      type: type,
      status: "active",
      order_id: "",
    });
    setIsChecked(false);
    localStorage.removeItem("formData");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataWithIntegers = {
      ...formData,
      bookingId: parseInt(formData.bookingId, 10),
      phone: parseInt(formData.phone, 10),
      age: parseInt(formData.age, 10),
    };

    if (formData.paymentMethod === "Online Payment") {
      initiateRazorpayPayment();
    } else {
      try {
        const response = await actions.submitAppointmentForm(
          formDataWithIntegers
        );

        if (response.statusCode === 200) {
          setAlert({
            show: true,
            variant: "success",
            message: response.message,
          });
          clearFormData(); 
        } else {
          setAlert({
            show: true,
            variant: "danger",
            message: `Error: ${response.error}`,
          });
        }
      } catch (error) {
        console.error("Error submitting appointment form:", error);
        setAlert({
          show: true,
          variant: "danger",
          message: "Failed to submit appointment",
        });
      }
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const initiateRazorpayPayment = async () => {
    try {
      const serviceCharge = formData.testPrice * 0.02;
      const totalAmount = formData.testPrice + serviceCharge;

      const orderResponse = await axios.post(
        "https://api.poloscananddiagnostics.com/v1/api/razorpay/create-order",
        {
          amount: totalAmount,
          currency: "INR",
          receipt: `receipt_${formData.bookingId}`,
        }
      );

      const { id: order_id } = orderResponse.data;

      setFormData((prevState) => ({
        ...prevState,
        order_id: order_id,
      }));

      const options = {
        key: razorpayKey,
        amount: totalAmount,
        currency: "INR",
        name: "Polo Scan and Diagnostic",
        description: `Payment for ${formData.testName}`,
        image: logo,
        order_id: order_id,
        handler: async function (response) {
          const paymentResult = await axios.post(
            "https://api.poloscananddiagnostics.com/v1/api/razorpay/verify-order",
            {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              currency: "INR",
              payment_method: "razorpay",
            }
          );

          if (paymentResult.data.status === "captured") {
            const updatedFormData = {
              ...formData,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
            };

            const formResponse = await actions.submitAppointmentForm(
              updatedFormData
            );

            if (formResponse.statusCode === 200) {
              setAlert({
                show: true,
                variant: "success",
                message: formResponse.message,
              });
              clearFormData();
            } else {
              setAlert({
                show: true,
                variant: "danger",
                message: `Error: ${formResponse.error}`,
              });
            }
          } else {
            setAlert({
              show: true,
              variant: "danger",
              message: "Payment failed. Please try again.",
            });
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.phone,
        },
        notes: {
          address: "Polo Scan and Diagnostic Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error initiating Razorpay payment:", error);
      setAlert({
        show: true,
        variant: "danger",
        message: "Failed to initiate payment",
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("formData");
    };
  }, []);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    validateForm({ ...formData });
  };

  return (
    <Container fluid className={classes.BookAppointmentContainer}>
      <Stack direction="horizontal" className={classes.HompageHeadercontainer}>
        <Stack className={classes.Headerheading}>
          <h1 className={classes.Headerh1}>
            Book an appointment <br /> now and prioritize <br /> your health!
          </h1>
          <div className={classes.Headerp}>
            <p>
              At Polo Scan and Diagnostic, we understand the
              <br /> importance of convenient access to quality
              <br /> healthcare services. That's why we offer a
              <br /> hassle-free online booking system for lab tests,
              <br /> making it easier than ever for you to take control
              <br /> of your health.
            </p>
          </div>
        </Stack>
        <Stack className={classes.HompageHeaderimage}>
          <Image src={banner} alt="Banner" className={classes.ImageStyle} />
        </Stack>
      </Stack>
      <Stack>
        <h2 className={classes.formTitle}>Book Appointment</h2>
        {alert.show && (
          <Alert
            variant={alert.variant}
            onClose={() => setAlert({ show: false })}
            dismissible
          >
            {alert.message}
          </Alert>
        )}
        <Form onSubmit={handleSubmit} className={classes.formContainer}>
          <Stack direction="horizontal" gap={4} className={classes.formGroup}>
            <Form.Control
              className={classes.formControl}
              type="text"
              placeholder="Patient name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={touched.name && !!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
            <Form.Control
              className={classes.formControl}
              type="text"
              placeholder="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              isInvalid={touched.phone && !!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Stack>
          <Stack direction="horizontal" gap={4} className={classes.formGroup}>
            <Form.Control
              className={classes.formControl}
              type="text"
              placeholder="Age"
              name="age"
              value={formData.age}
              onChange={handleChange}
              isInvalid={touched.age && !!errors.age}
            />
            <Form.Control.Feedback type="invalid">
              {errors.age}
            </Form.Control.Feedback>
            <p className={classes.bookingtag}>*Booking Date*</p>
            <Form.Control
              className={classes.formControl}
              type="date"
              placeholder="mm/dd/yyyy"
              name="date"
              value={formData.date}
              min={getCurrentDate()}
              onChange={handleDateChange}
              onFocus={handleDateFocus}
              onBlur={handleDateBlur}
              isInvalid={touched.date && !!errors.date}
            />
            <Form.Control.Feedback type="invalid">
              {errors.date}
            </Form.Control.Feedback>
            <p className={classes.bookingtag}>*Booking Time*</p>
            <Form.Control
              className={classes.formControl}
              type="time"
              placeholder="--:-- --"
              name="time"
              value={formData.time}
              onChange={handleChange}
              isInvalid={touched.time && !!errors.time}
            />
            <Form.Control.Feedback type="invalid">
              {errors.time}
            </Form.Control.Feedback>
          </Stack>

          <Stack direction="horizontal" gap={5} className={classes.formGroup}>
            <Form.Control
              className={classes.formControl}
              type="text"
              placeholder="Test Name"
              name="testName"
              value={formData.testName}
              onChange={handleChange}
              disabled
            />
            <Form.Control
              className={classes.formControl}
              type="text"
              placeholder="Test Price"
              name="testPrice"
              value={formData.testPrice}
              onChange={handleChange}
              disabled
            />
          </Stack>
          <div className={classes.radioGroup}>
            <div>
              <Form.Label className={classes.formLabel}>
                Payment Method:
              </Form.Label>
              <Form.Check
                inline
                type="radio"
                label="Cash At Counter"
                name="paymentMethod"
                value="Cash At Counter"
                checked={formData.paymentMethod === "Cash At Counter"}
                onChange={handleChange}
                isInvalid={touched.paymentMethod && !!errors.paymentMethod}
              />
              <Form.Check
                inline
                type="radio"
                label="Online Payment"
                name="paymentMethod"
                value="Online Payment"
                checked={formData.paymentMethod === "Online Payment"}
                onChange={handleChange}
                isInvalid={touched.paymentMethod && !!errors.paymentMethod}
              />
              <Form.Control.Feedback type="invalid">
                {errors.paymentMethod}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className={classes.radioGroup}>
            <div>
              <Form.Label className={classes.formLabel}>
                Sample Collection:
              </Form.Label>
              <Form.Check
                inline
                type="radio"
                label="Home Collection"
                name="sampleCollection"
                value="Home Collection"
                checked={formData.sampleCollection === "Home Collection"}
                onChange={handleChange}
                isInvalid={
                  touched.sampleCollection && !!errors.sampleCollection
                }
                disabled
              />
              <Form.Check
                inline
                type="radio"
                label="At Center"
                name="sampleCollection"
                value="At Center"
                checked={formData.sampleCollection === "At Center"}
                onChange={handleChange}
                isInvalid={
                  touched.sampleCollection && !!errors.sampleCollection
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.sampleCollection}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className={classes.radioGroup}>
            
            <Form.Check
              type="checkbox"
              label={
                <span>
                  I agree to the{" "}
                  <a
                    href="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and conditions
                  </a>
                </span>
              }
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </div>

          <Stack className={classes.submitButtonContainer}>
            <Button
              type="submit"
              className={classes.submitButton}
              disabled={!isFormValid}
            >
              Submit
            </Button>
          </Stack>
        </Form>
      </Stack>
    </Container>
  );
};

export default BookAppointment;
